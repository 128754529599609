
body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
