/* Waitlist Page Styles */
.waitlist-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
}

.waitlist-container {
    max-width: 600px;
    width: 100%;
    text-align: center;
    margin-top: 5vh;
}

.waitlist-title {
    font-size: 2.5em;
    color: #ffffff;
    margin-bottom: 2vh;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
}

.waitlist-subtitle {
    font-size: 1.2em;
    color: #000000;
    line-height: 1.5;
    margin-bottom: 5vh;
}

/* Waitlist Form Styles */
.waitlist-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
}

.waitlist-input {
    width: 80%;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 25px;
    box-sizing: border-box;
}

.waitlist-submit {
    padding: 10px;
    font-size: 1em;
    color: #fff;
    background: linear-gradient(-135deg, #8626F4, #A460F1, #C294F6, #E3CAFF);
    border: none;
    border-radius: 25px;
    width: 200px;
    border: 1px solid #000000;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.waitlist-submit:hover {
    transform: translateY(-1px) translateX(1px);
}

.waitlist-submit.submitted {
    background-color: #000;
    color: #fff;
}

.waitlist-message {
    font-size: 1em;
    color: #000000;
    margin-top: 10px;
}

/* Media Queries for Responsiveness */
@media (max-width: 700px) {
    .waitlist-container {
        width: 90%;
    }

    .waitlist-title {
        font-size: 2em;
    }

    .waitlist-input {
        width: 90%;
    }
}

@media (min-width: 701px) {
    .waitlist-container {
        width: 600px;
    }
}
