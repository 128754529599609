/* Global Styles */
* {
    cursor: default;
}

/* Scroll Container */
.scroll-container {
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory; /* Enables snap scrolling */
    scroll-behavior: smooth; /* Smooth scroll effect */
}

/* Main Content */
.main-content {
    scroll-snap-align: start;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Centered Layout */
.centered-layout {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80%;
    max-width: 1200px;
    gap: 40px;
}

.landing-title {
    flex: 1;
    text-align: left;
}

.landing-title h1 {
    font-size: 3em;
    margin: 0;
    line-height: 1.7;
    font-weight: 400;
}

.cards-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 20px;
    overflow-x: auto;
}

/* Rotating Highlighted Text */
.highlighted-text {
    display: inline-block;
    background: linear-gradient(-135deg, #8626F4, #A460F1, #C294F6, #E3CAFF);
    padding: 5px 10px;
    border: 2px solid black;
    border-radius: 25px;
    color: black;
    line-height: 1.5;
    box-shadow: 2px -2px 20px rgba(0, 0, 0, 1);
    margin-left: 10px;
}

.text1 {
    font-size: 1.2em;
    font-weight: bold;
}

/* Section Layouts */
.sec2, .sec3, .sec4 {
    scroll-snap-align: start;
    height: 100vh;
}

.sec2content, .sec3content, .sec4content {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding-left: 15vw;
    height: 100%;
}

/* Section Titles and Text */
.sect {
    color: #000000;
    margin-bottom: 0;
    font-size: 2em;
}

.secm {
    margin-top: 0;
    margin-bottom: 4vh;
    font-size: 2.5em;
    color: #000000;
}

.secd1 {
    color: #000000;
    margin-top: 10vh;
    font-size: 1.5em;
}

/* Content Box */
.content-box {
    background: linear-gradient(45deg, rgba(237, 237, 237, 0.8), rgba(185, 185, 185, 0.8));
    border: 4px solid black;
    border-radius: 25px;
    padding: 40px;
    max-width: 800px;
}

.content-box .sect {
    color: #000000;
    margin-bottom: 0;
    font-size: 2em;
    font-weight: 750;
}

.content-box .secm {
    margin-top: 0;
    margin-bottom: 4vh;
    font-size: 2.5em;
    font-weight: 200;
}

.content-box .secd1 {
    color: #000000;
    margin-top: 10vh;
    font-size: 1.5em;
    font-weight: 80;
}

/* Responsive Layout */
@media (max-width: 750px) {
    .centered-layout {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .landing-title {
        text-align: center;
        margin-top: 100px; /* Add extra margin for mobile */
    }

    .cards-container {
        width: 100%;
        justify-content: center;
        flex-direction: column;
    }
}

@media (max-width: 700px) {
    .sec2content, .sec3content, .sec4content, .sect, .secm, .secd1 {
        align-items: center;
        padding: 0;
        max-width: 330px;
        margin: 0 auto;
        text-align: center;
    }
    
    .sect {
        margin-bottom: 2vh;
        margin-top: -8vh;
    }
    
    .secm {
        margin-bottom: 7vh;
    }

    .content-box {
        padding: 20px;
        max-width: 330px;
        text-align: center;
    }
    
    .content-box .sect {
        margin-bottom: 2vh;
        margin-top: -8vh;
    }
    
    .content-box .secm {
        margin-bottom: 7vh;
    }
}
