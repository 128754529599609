/* Feedback Page Styles */
.feedback-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
}

.feedback-container {
    max-width: 600px;
    width: 100%;
    text-align: center;
    margin-top: 125px;
}

.feedback-title {
    font-size: 2.5em;
    color: #ffffff;
    margin-bottom: 2vh;
    text-align: center;
    width: 100%; /* Ensures the title takes full width within the container */
    display: flex;
    justify-content: center; /* Center-aligns the title content */
}

.feedback-subtitle {
    font-size: 1.2em;
    color: #000000;
    line-height: 1.5;
    margin-bottom: 5vh;
}

/* Feedback Form Styles */
.feedback-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
}

.feedback-input,
.feedback-textarea {
    width: 80%;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    border-radius: 25px;
}

.feedback-textarea {
    height: 100px;
    resize: vertical;
}

.feedback-submit {
    padding: 10px;
    font-size: 1em;
    color: #fff;
    background: linear-gradient(-135deg, #8626F4, #A460F1, #C294F6, #E3CAFF);
    border: none;
    border-radius: 5px;
    width: 200px;
    border: 1px solid;
    border-color: #000000;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.feedback-submit:hover {
    transform: translateY(-1px) translateX(1px);
}


.feedback-message {
    font-size: 1em;
    color: #000000;
    margin-top: 10px;
}

/* Media Queries for Responsiveness */
@media (max-width: 700px) {
    .feedback-container {
        width: 90%;
    }

    .feedback-title {
        font-size: 2em;
    }

    .feedback-input,
    .feedback-textarea {
        width: 90%;
    }
}

@media (min-width: 701px) {
    .feedback-container {
        width: 600px;
    }
}

.feedback-submit {
    padding: 10px;
    font-size: 1em;
    color: #fff;
    background: linear-gradient(-135deg, #8626F4, #A460F1, #C294F6, #E3CAFF);
    border: none;
    border-radius: 25px;
    width: 200px;
    border: 1px solid #000000;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.feedback-submit.submitted {
    background-color: #000; /* Black background */
    color: #fff; /* White text */
}

