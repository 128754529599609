* {
  cursor: default;
}

/* Header Container */
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space items to opposite ends */
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 1200px;
  z-index: 1000;
  margin-top: 20px;
}

/* Zuria Bubble */
.zuria-bubble {
  background-color: #EDEDED;
  border-radius: 25px;
  padding: 0 15px;
  box-shadow: 2px -2px 20px rgba(0, 0, 0, 1);
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid black;
  font-weight: bold;
}

.zuria-bubble:hover {
  background: linear-gradient(-135deg, #8626F4, #A460F1, #C294F6, #E3CAFF);
}

.zuria-name {
  color: #000000;
}

/* Menu Icon Styling */
.menu-icon {
  height: 40px; /* Matches zuria-bubble height */
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-left: auto; /* Pushes the icon to the far right */
}

.menu {
  width: auto;
  height: 40px;
}

/* Header Styling */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'DM Sans', sans-serif;
  height: 40px;
  background: linear-gradient(45deg, #EDEDED, #B9B9B9);
  border-radius: 25px;
  flex: 1;
  border: 1px solid black;
  box-shadow: 2px -2px 20px rgba(0, 0, 0, 1);
  margin-right: 15px; /* Aligns with zuria-bubble padding */
  padding: 0 10px;
  margin-left: 10px;
}

.header-left, .header-right {
  display: flex;
  align-items: center;
}

.nav-item {
  margin: 0 10px;
  font-size: 14px;
  color: black;
  cursor: pointer;
  transition: color 0.2s ease;
}

.nav-item:hover {
  color: #8626F4;
}

@media (min-width: 600px) {
  .menu {
    display: none;
  }
}

@media (max-width: 600px) {
  .header {
    display: none;
  }

  .menu {
    display: block;
  }
}

/* Overlay Menu Styles */
.overlay-menu {
  position: fixed;
  width: 250px;
  height: 250px;
  top: 325%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centers the overlay menu */
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  border: 1px solid #000000;
  border-radius: 25px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  z-index: 1000;
}


.overlay-item {
  font-size: 1em;
  color: #000000;
  background: linear-gradient(-135deg, #8626F4, #A460F1, #C294F6, #b490dd);
  border: 1px solid #000000;
  border-radius: 25px;
  margin: 10px 0;
  cursor: pointer;
  padding: 10px;
  transition: color 0.3s;
}

.overlay-item:hover {
  color: #A460F1;
}
