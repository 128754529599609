/* App.css */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: linear-gradient(-135deg, #8626F4, #A460F1, #C294F6, #E3CAFF);
  color: #FFFFFF;
  font-family: 'DM Sans', sans-serif;
  overflow-x: hidden; /* Prevents horizontal scroll */
}

.app {
  text-align: center;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


/* App Logo Animation */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-link {
  color: #61dafb;
}



