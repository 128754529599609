

.card-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.card-rotation-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
    overflow-x: scroll; /* Enable horizontal scrolling */
    position: relative;
    cursor: grab;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for a cleaner look */
.card-rotation-container::-webkit-scrollbar {
    display: none;
}

/* Rest of the existing styles for cards */
.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    color: #333333;
    border: 2px solid black;
    border-radius: 12px;
    padding: 20px;
    margin: 10px;
    width: 220px;
    height: 400px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    text-align: center;
    transition: box-shadow 0.3s ease;
}

.card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Responsive Card Layout for smaller screens */
@media (max-width: 750px) {
    .card {
        width: 100%;
        height: auto;
    }
}


.card-rotation-wrapper {
  position: relative;
  width: 100%;
}

.blur-left, 
.blur-right {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 40px;
  pointer-events: none;
  background: linear-gradient(to right, rgba(164, 96, 241, 1), rgba(255, 255, 255, 0));
  z-index: 1;
  transition: opacity 0.3s ease;
  opacity: 0; /* Default hidden */
  border: transparent;
}

.blur-left {
  border-radius: 0px 10px 10px 0px;
}

.blur-right {
  border-radius: 10px 0px 0px 10px;
}

.blur-left {
  left: 0;
  background: linear-gradient(to left, rgba(164, 96, 241, 0), rgba(194, 153, 241, 0.7));
}

.blur-right {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(164, 96, 241, 0.7));
}

.blur-left.show {
  opacity: 1; /* Show when scrolled away from left edge */
}

.blur-right.show {
  opacity: 1; /* Show when scrolled away from right edge */
}



/* Card Styling */
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  color: #333333;
  border: 2px solid black;
  border-radius: 12px;
  padding: 20px;
  margin: 10px;
  width: 220px;
  height: 400px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  text-align: center;
  transition: box-shadow 0.3s ease;
}

.card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Card Sections */
.card-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.title-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  text-align: center;
  margin-top: 40px;
}

.column-title {
  font-weight: bold;
  font-size: 1.2em;
  color: #000000;
  line-height: 1.2;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.logo-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85px;
  margin-top: 20px;
}

.logo-img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.text-section {
  margin-top: 25px;
  height: 300px;
}

.price-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  margin-bottom: 15px;
}

.price {
  font-size: 1.4em;
  color: #28a745;
}

.price-tag-section,
.description-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.description-section {
  padding-bottom: 5px;
  padding-top: 5px;
}

.description {
  font-size: 0.9em;
  color: #666666;
  line-height: 1.2;
  padding: 5px;
}

.button-section {
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.try-now-button {
  background-color: #000000;
  color: white;
  font-size: 1em;
  padding: 8px 16px;
  border: 1px solid black;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.try-now-button:hover {
  background: linear-gradient(-135deg, #8626f4, #a460f1, #c294f6, #e3caff);
  color: #000000;
  border: 2px solid black;
  font-weight: bold;
}
